import React, { useState, useEffect } from 'react';
import {
  TextField,
  FormControlLabel,
  Checkbox,
  Button,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Alert,
  Typography,
  Paper,
  createTheme,
  ThemeProvider,
  Card,
  CardContent,
  Grid,
} from '@mui/material';

const SimpleCurrencyConverter = () => {
  const [currency, setCurrency] = useState('');
  const [amount, setAmount] = useState('');
  const [toCurrency, setToCurrency] = useState('');
  const [reverse, setReverse] = useState(false);
  const [result, setResult] = useState(null);
  const [error, setError] = useState('');
  const [currencies, setCurrencies] = useState([]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setResult(null);

    const payload = reverse
      ? { currency: 'USDT', amount: parseFloat(amount), to: toCurrency }
      : { currency: currency, amount: parseFloat(amount) };

    try {
      const response = await fetch('https://broker.voidnix.workers.dev/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        throw new Error('API request failed');
      }

      const data = await response.json();
      setResult(data);
    } catch (err) {
      setError('Failed to fetch data. Please try again.');
    }
  };

  useEffect(() => {
    const fetchCurrencies = async () => {
      try {
        const response = await fetch('https://currency-payexcro.vercel.app/api/currency');
        const data = await response.json();
        const availableCurrencies = data.currencies.filter((currency) => currency.available);
        setCurrencies(availableCurrencies);
      } catch (err) {
        setError('Failed to fetch currencies.');
      }
    };
    fetchCurrencies();
  }, []);

  // Clear appropriate fields when reverse changes
  useEffect(() => {
    if (reverse) {
      setToCurrency('');
    } else {
      setCurrency('');
    }
  }, [reverse]);

  // Create a dark theme
  const darkTheme = createTheme({
    palette: {
      mode: 'dark',
    },
  });

  // Function to check if amount is a valid number
  const isAmountValid = () => {
    return amount !== '' && !isNaN(amount);
  };

  // Function to format currency values
  const formatCurrency = (value) => {
    return parseFloat(value).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  };

  return (
    <ThemeProvider theme={darkTheme}>
      <div style={{ maxWidth: '600px', margin: '0 auto', paddingTop: '2rem' }}>
        <Typography variant="h4" style={{ marginLeft: '1rem', marginBottom: '1rem' }}>
          Currency Converter
        </Typography>

        <Paper elevation={3} style={{ padding: '2rem' }}>
          <form onSubmit={handleSubmit}>
            {/* Currency Dropdown */}
            <FormControl fullWidth margin="normal">
              <InputLabel id="currency-label">Currency</InputLabel>
              <Select
                labelId="currency-label"
                id="currency"
                value={reverse ? 'USDT' : currency}
                label="Currency"
                onChange={(e) => setCurrency(e.target.value)}
                disabled={reverse}
                renderValue={(selected) => {
                  if (reverse) {
                    return 'USDT';
                  } else {
                    const selectedCurrency = currencies.find((cur) => cur.code === selected);
                    return selectedCurrency ? `${selectedCurrency.name} (${selectedCurrency.symbol})` : '';
                  }
                }}
              >
                {!reverse && currencies.map((cur) => (
                  <MenuItem key={cur.code} value={cur.code}>
                    {cur.name} ({cur.symbol})
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            {/* To Currency Dropdown */}
            <FormControl fullWidth margin="normal">
              <InputLabel id="toCurrency-label">To Currency</InputLabel>
              <Select
                labelId="toCurrency-label"
                id="toCurrency"
                value={reverse ? toCurrency : 'USDT'}
                label="To Currency"
                onChange={(e) => setToCurrency(e.target.value)}
                disabled={!reverse}
                renderValue={(selected) => {
                  if (!reverse) {
                    return 'USDT';
                  } else {
                    const selectedCurrency = currencies.find((cur) => cur.code === selected);
                    return selectedCurrency ? `${selectedCurrency.name} (${selectedCurrency.symbol})` : 'Select target currency';
                  }
                }}
              >
                {reverse && (
                  <MenuItem value="">
                    <em>Select target currency</em>
                  </MenuItem>
                )}
                {reverse && currencies.map((cur) => (
                  <MenuItem key={cur.code} value={cur.code}>
                    {cur.name} ({cur.symbol})
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            {/* Amount Field */}
            <TextField
              id="amount"
              label="Amount"
              variant="outlined"
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
              type="number"
              fullWidth
              margin="normal"
            />

            {/* Reverse Conversion Checkbox */}
            <FormControlLabel
              control={
                <Checkbox
                  checked={reverse}
                  onChange={() => setReverse(!reverse)}
                />
              }
              label="Reverse Conversion (From USDT to Currency)"
            />

            {/* Submit Button */}
            <Button
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              style={{ marginTop: '1rem' }}
              disabled={!isAmountValid()}
            >
              Convert
            </Button>
          </form>

          {/* Error Message */}
          {error && (
            <Alert severity="error" style={{ marginTop: '1rem' }}>
              {error}
            </Alert>
          )}

          {/* Conversion Result */}
          {result && (
            <div style={{ marginTop: '2rem' }}>
              <Typography variant="h5" gutterBottom>
                Conversion Result
              </Typography>

              <Card variant="outlined" style={{ marginBottom: '1rem' }}>
                <CardContent>
                  <Typography variant="subtitle1">
                    <strong>Base Amount:</strong> {formatCurrency(result['Base Amount'])} {result['Currency Code']}
                  </Typography>
                  {reverse ? (
                    <Typography variant="subtitle1">
                      <strong>Asking Price:</strong> {formatCurrency(result['Asking Price'])}
                    </Typography>
                  ) : (
                    <Typography variant="subtitle1">
                      <strong>USDT to Send:</strong> {formatCurrency(result['USDT to Send'])}
                    </Typography>
                  )}
                </CardContent>
              </Card>

              <Grid container spacing={2}>
                {result.razorpayFees && (
                  <Grid item xs={12} sm={4}>
                    <Card variant="outlined">
                      <CardContent>
                        <Typography variant="h6">Razorpay Fees</Typography>
                        <Typography variant="body2">
                          <strong>Fees:</strong> {formatCurrency(result.razorpayFees.Fees)}
                        </Typography>
                        <Typography variant="body2">
                          <strong>Tax:</strong> {formatCurrency(result.razorpayFees.Tax)}
                        </Typography>
                        <Typography variant="body2">
                          <strong>Fee + Tax:</strong> {formatCurrency(result.razorpayFees['Fee + Tax'])}
                        </Typography>
                        <Typography variant="body2">
                          <strong>Price with fees:</strong> {formatCurrency(result.razorpayFees['Price with fees'])}
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                )}

                {result.payPalFees && (
                  <Grid item xs={12} sm={4}>
                    <Card variant="outlined">
                      <CardContent>
                        <Typography variant="h6">PayPal Fees</Typography>
                        <Typography variant="body2">
                          <strong>Fees:</strong> {formatCurrency(result.payPalFees.Fees)}
                        </Typography>
                        <Typography variant="body2">
                          <strong>Tax:</strong> {formatCurrency(result.payPalFees.Tax)}
                        </Typography>
                        <Typography variant="body2">
                          <strong>Fee + Tax:</strong> {formatCurrency(result.payPalFees['Fee + Tax'])}
                        </Typography>
                        <Typography variant="body2">
                          <strong>Price with fees:</strong> {formatCurrency(result.payPalFees['Price with fees'])}
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                )}

                {result.stripeFees && (
                  <Grid item xs={12} sm={4}>
                    <Card variant="outlined">
                      <CardContent>
                        <Typography variant="h6">Stripe Fees</Typography>
                        <Typography variant="body2">
                          <strong>Fees:</strong> {formatCurrency(result.stripeFees.Fees)}
                        </Typography>
                        {result.stripeFees.Tax && (
                          <Typography variant="body2">
                            <strong>Tax:</strong> {formatCurrency(result.stripeFees.Tax)}
                          </Typography>
                        )}
                        {result.stripeFees['Fee + Tax'] && (
                          <Typography variant="body2">
                            <strong>Fee + Tax:</strong> {formatCurrency(result.stripeFees['Fee + Tax'])}
                          </Typography>
                        )}
                        <Typography variant="body2">
                          <strong>Price with fees:</strong> {formatCurrency(result.stripeFees['Price with fees'])}
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                )}
              </Grid>
            </div>
          )}
        </Paper>
      </div>
    </ThemeProvider>
  );
};

export default SimpleCurrencyConverter;
