import React from 'react';
import { AppBar, Toolbar, Button } from '@mui/material';
import { FaDiscord } from "react-icons/fa";
import Logo from './logo.png';

const Header = () => {
  return (
    <AppBar position="static" className="bg-transparent shadow-none">
      <Toolbar className="flex justify-between items-center px-4">
        {/* Left side: Logo */}
        <div className="flex-grow-0">
          <img src={Logo} alt="Logo" style={{ height: '4.5rem' }} />
        </div>
        
        {/* Right side: Join Discord button */}
        <div className="flex-grow-0">
          <Button
            variant="contained"
            color="primary"
            startIcon={<FaDiscord />}
            className="normal-case"
            onClick={() => window.open('https://discord.payexcro.com', '_blank')}
          >
            Join Discord
          </Button>
        </div>
      </Toolbar>
    </AppBar>
  );
};

export default Header;